import { createStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

export const getStyles = (theme) => {
  return {
    section: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
}

const styles = (theme) => {
  const styleClasses = getStyles(theme)
  return createStyles(styleClasses)
}

export class Section extends Component {
  render() {
    const { children, classes } = this.props
    return <section className={classes.section}>{children}</section>
  }
}

export default withStyles(styles)(Section)
