import { createStyles, withStyles } from '@material-ui/core'
import { Section, getStyles } from './Section'

const styles = (theme) => {
  const styleClasses = getStyles(theme)
  return createStyles({
    ...styleClasses,
    section: {
      ...styleClasses.section,
      padding: '3.75rem 0',
    },
  })
}

export default withStyles(styles)(Section)
