import { createStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

const styles = (theme) =>
  createStyles({
    header: {
      marginBottom: '2.5rem',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
  })

class SectionHeader extends Component {
  render() {
    const { classes, children } = this.props
    return <div className={classes.header}>{children}</div>
  }
}

export default withStyles(styles)(SectionHeader)
