import React, { Component } from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Container from '../../components/Container'
import food from './food.jpg'
import Item from './Item'

const styles = (theme) =>
  createStyles({
    section: {
      backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.67), rgba(25, 26, 27, 0.19)), url(${food})`,
      padding: '6.25rem 0',
      backgroundPosition: '0 0, 50% 50%',
      backgroundRepeat: 'repeat, no-repeat',
      backgroundSize: 'auto, cover',
      backgroundAttachment: 'scroll, fixed',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto auto auto',
      gridAutoColumns: '1fr',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gridAutoColumns: '1fr',
      },
    },
  })

class Info extends Component {
  render() {
    const { classes } = this.props
    return (
      <section className={classes.section}>
        <Container>
          <div className={classes.content}>
            <Item statistic="1000+" name="Orders Served" />
            <Item statistic="500+" name="Active Users" />
            <Item statistic="700+" name="Delivered" />
            <Item statistic="400+" name="Takeaways" />
          </div>
        </Container>
      </section>
    )
  }
}

export default withStyles(styles)(Info)
