import React, { Component } from 'react'
import { createStyles, withStyles, Typography } from '@material-ui/core'

import Wave from './Wave'

const styles = () => {
  const color = 'white'
  return createStyles({
    item: {
      listStyle: 'none',
      textAlign: 'center',
      color,
    },
    statistic: {
      marginBottom: 0,
      fontSize: '4.6875rem',
      color,
    },
    wave: {
      width: '5.2687rem',
      height: '1.1287rem',
      margin: '1.25rem 0',
    },
    name: {
      fontSize: '1.25rem',
    },
  })
}

class Item extends Component {
  render() {
    const { classes, statistic, name } = this.props
    return (
      <li className={classes.item}>
        <Typography variant="h1" className={classes.statistic}>
          {statistic}
        </Typography>
        <Wave className={classes.wave} />
        <Typography className={classes.name}>{name}</Typography>
      </li>
    )
  }
}

export default withStyles(styles)(Item)
