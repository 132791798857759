import React, { Component } from 'react'
import {
  Button as BaseButton,
  createStyles,
  withStyles,
} from '@material-ui/core'

export const buttonStyles = {
  button: {
    padding: '1.25rem 2.1875rem',
    borderRadius: '0.375rem',
    fontSize: '1.125rem',
    boxShadow: 'none',
    backgroundImage: 'url(/images/Wave.svg)',
    backgroundPosition: '0px 0px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}
const styles = () => createStyles(buttonStyles)

export class Button extends Component {
  render() {
    const { classes, ...other } = this.props
    return <BaseButton className={classes.button} {...other}></BaseButton>
  }
}

export default withStyles(styles)(Button)
