import React from 'react'
const { createStyles, withStyles } = require('@material-ui/core')

const styles = (theme) =>
  createStyles({
    image: {
      marginTop: '3.75rem',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
  })

const useImageStyles = (Image) =>
  withStyles(styles)((props) => <Image className={props.classes.image} />)

export default useImageStyles
