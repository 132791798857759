import React, { Component } from 'react'
import { createStyles, withStyles, Typography } from '@material-ui/core'

import { buttonStyles as baseButtonStyles, Button } from '../Button'

const buttonStyles = createStyles({
  ...baseButtonStyles,
  button: {
    ...baseButtonStyles.button,
    '&:hover': {
      backgroundColor: '#ff7218',
    },
  },
})

const StyledButton = withStyles(buttonStyles)(Button)

const styles = (theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
  })

class Link extends Component {
  render() {
    const { classes, children, ...other } = this.props
    return (
      <div className={classes.buttonContainer}>
        <StyledButton variant="contained" color="primary" {...other}>
          <Typography variant="button">{children}</Typography>
        </StyledButton>
      </div>
    )
  }
}

export default withStyles(styles)(Link)
