import React, { Component } from 'react'

import Layout from '../components/Layout'
import Header from './Header'
import FreshProducts from './FreshProducts'
import Info from './Info'

export class About extends Component {
  render() {
    return (
      <Layout>
        <Header />
        <FreshProducts />
        <Info />
      </Layout>
    )
  }
}

export default About
