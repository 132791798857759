import React, { Component } from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import image from '../../images/logo-monochrome.png'

const styles = ({ palette }) => {
  return createStyles({
    container: {
      backgroundColor: palette.primary.main,
      padding: '6.25rem 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      width: '15.9375rem',
      height: 'auto',
    },
  })
}

class Header extends Component {
  render() {
    const { classes } = this.props
    return (
      <header className={classes.container}>
        <img src={image} className={classes.image} alt="Air Dining" />
      </header>
    )
  }
}

export default withStyles(styles)(Header)
