import { createStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

export const getStyles = (theme) => {
  const width = '50%'
  return {
    content: {
      [theme.breakpoints.up('md')]: {
        flexBasis: width,
        width,
        'svg + &': {
          marginLeft: '2.5rem',
        },
        '& + svg': {
          marginLeft: '2.5rem',
        },
      },
    },
  }
}

const styles = (theme) => {
  const styleClasses = getStyles(theme)
  return createStyles(styleClasses)
}

export class Body extends Component {
  render() {
    const { classes, children } = this.props
    return <div className={classes.content}>{children}</div>
  }
}

export default withStyles(styles)(Body)
