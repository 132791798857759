import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import ContentSection from '../../components/sections/ContentSection'
import SectionBody from '../../components/sections/Section/Body'
import SectionHeader from '../../components/sections/SectionHeader'
import Link from '../../components/sections/Link'
import Container from '../../components/Container'
import useImageStyles from '../../utils/imageStyles'
import Image from './Image'

const StyledImage = useImageStyles(Image)

export class FreshProducts extends Component {
  render() {
    return (
      <Container>
        <ContentSection>
          <SectionBody>
            <SectionHeader>
              <Typography variant="h2">The home of fresh products</Typography>
              <Typography variant="body2">
                Behind Air Dining, there is an exceptional team that is united
                by a single objective to improve guest experience across the
                hospitality industry. We share a passion for building excellent
                software that makes people’s lives more convenient, with an
                unparalleled usability that simply works.
              </Typography>
            </SectionHeader>
            <Link href="https://goswamisiddh.typeform.com/to/WOgUiiTa">
              Join us
            </Link>
          </SectionBody>
          <StyledImage />
        </ContentSection>
      </Container>
    )
  }
}

export default FreshProducts
